<template>
  <v-app class="layout-bg">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-avatar size="100">
              <v-img :src="logo" :lazy-src="logo"></v-img>
            </v-avatar>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="primary"></v-divider>
      <v-list dense>
        <v-list-item
          v-for="(link, i) in links"
          :key="i"
          :to="link.to"
          link
          exact
        >
          <v-list-item-icon class="text--primary">
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="text--primary">
            {{ link.title }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" flat app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-h6 text-capitalize primary--text">
        {{ $route.name !== "UserDetails" ? pageName : user.fullname }}
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-footer class="white">
      <v-card-text>&copy; Havil {{ new Date().getFullYear() }}</v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "authenticated",
  data() {
    return {
      links: [
        {
          to: "/dashboard",
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
        },
        {
          to: "/users",
          title: "Users",
          icon: "mdi-account-group-outline",
        },
        {
          to: "/transactions",
          title: "Transactions",
          icon: "mdi-chart-line",
        },
        {
          to: "/sign_in",
          title: "Logout",
          icon: "mdi-power",
        },
      ],
      drawer: !!this.$vuetify.breakpoint.mdAndUp,
      logo: require("@/assets/logo.png")
    };
  },
  computed: {
    ...mapGetters("users", ["user"]),
    pageName() {
      return this.$route.path.replace("/", "");
    },
  },
};
</script>

<style scoped>
.layout-bg {
  background: #f4fcff;
}
</style>
